import React, { useState, useEffect } from "react";
import { AutoComplete, Modal, Select, Switch } from "antd";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { validationAntService as validation } from "../../utils/validationAntService";
import { InputNumber } from "antd";
import { Checkbox } from "antd";
import PhoneInput from "../PhoneInput/PhoneInput";
import "antd/dist/antd.css";

const { Option } = Select;

const MIN_VALUE = 0;
const MAX_VALUE = 1000000000;

//TODO create flexible modal with composition pattern for next pages components
export default function ModalUpdateComponent({
  index,
  form,
  modalSubmitOk,
  modal,
  onModalCancelHandler,
  modalComponentsData,
  allItems,
  mainField,
  mode,
  currentItem,
}) {
  const [dependentList, setDependentList] = useState([]);

  const {
    validatePassword,
    validateEmail,
    validateFieldDuplicate,
    validateMaxLength,
    validatePhone,
    validateAlphabet,
  } = validation();

  const trimFormFields = () => {
    const allValues = form.getFieldsValue();
    const trimFormValues = Object.entries(allValues).map((entity) => {
      return typeof entity[1] === "string" ? [entity[0], entity[1].trim()] : entity;
    });
    form.setFieldsValue(Object.fromEntries(trimFormValues));
  };

  const onOk = async () => {
    trimFormFields();
    await form.validateFields().then(async (values) => {
      await modalSubmitOk(values);
      form.resetFields();
    });
  };

  const changeDependentValue = (allItems, value, dependentFieldName) => {
    const chosenItem = allItems.find((item) => item.name === value);
    if (chosenItem) {
      const dependentListArray = chosenItem[dependentFieldName];
      form.setFieldsValue({ [dependentFieldName]: "" });
      setDependentList(dependentListArray);
    }
  };

  useEffect(() => {
    modalComponentsData.forEach((item) => {
      if (item.elementType === "main_select" && !item.notEditable) {
        if (Object.entries(currentItem).length) {
          //nestedField - The field by which we are looking for the name in the currentItem for editing
          changeDependentValue(item.elementList, currentItem[item.nestedField], item.dependentSelectName);
        }
      }
    });
  }, []);

  const renderElements = (item) => {
    if (item.elementType === "autocomplete") {
      return <AutoComplete filterOption={true} placeholder={item.placeholder} options={item.elementList} />;
    }
    if (item.elementType === "main_select") {
      return (
        <Select
          mode={item.elementType === "multiSelect" ? "multiple" : ""}
          name={item.name}
          onChange={(value) => changeDependentValue(item.elementList, value, item.dependentSelectName)}
          placeholder={item.placeholder}
        >
          {item.elementList.map((company, companyIdx) => {
            return (
              <Option key={companyIdx} value={company.name}>
                {company.name}
              </Option>
            );
          })}
        </Select>
      );
    } else if (item.elementType === "dependent_select") {
      return (
        <Select name={item.name} mode={item.mode} placeholder={item.placeholder}>
          {dependentList.map((company, index) => {
            return (
              <Option key={index} value={company.name}>
                {company.name}
              </Option>
            );
          })}
        </Select>
      );
    } else if (item.elementType === "input") {
      return <Input autoComplete="off" name={item.name} placeholder={item.placeholder} />;
    } else if (item.elementType === "input_password") {
      return <Input.Password name={item.name} autoComplete="new-password" placeholder={item.placeholder} />;
    } else if (item.elementType === "input_number") {
      return (
        <InputNumber
          min={MIN_VALUE}
          max={MAX_VALUE}
          type="number"
          name={item.name}
          autoComplete="off"
          placeholder={item.placeholder}
        />
      );
    } else if (item.elementType === "checkbox") {
      return <Checkbox name={item.name}>{item.placeholder}</Checkbox>;
    } else if (item.elementType === "phoneInput") {
      return <PhoneInput form={form} placeholder="Enter phone number" />;
    } else if (item.elementType === "switcher") {
      return <Switch name={item.name} checkedChildren={"Active"} unCheckedChildren={"Inactive"} />;
    } else if (item.elementType === "select" || item.elementType === "multiSelect") {
      return (
        <Select
          mode={item.elementType === "multiSelect" ? "multiple" : ""}
          name={item.name}
          disabled={item.disabled}
          placeholder={item.placeholder}
        >
          {item.elementList.map((company, companyIdx) => {
            return (
              <Option key={companyIdx} value={item.valuePath ? company[item.valuePath] : company.name}>
                {company.name}
              </Option>
            );
          })}
        </Select>
      );
    }
  };

  return (
    <Modal
      key={index}
      forceRender
      centered
      maskClosable={false}
      title={modal.text}
      visible={modal.isVisible}
      onOk={onOk}
      onCancel={onModalCancelHandler}
    >
      <Form form={form} name="normal_login" className="login-form" initialValues={{ remember: true }}>
        {modalComponentsData.map((item, itemIdx) => {
          return (
            <Form.Item
              style={{ position: "relative" }}
              key={itemIdx}
              label={item.label}
              labelAlign={"left"}
              labelCol={{ span: 7 }}
              valuePropName={item.elementType === "checkbox" || item.elementType === "switcher" ? "checked" : "value"}
              name={item.name}
              validateTrigger={["onBlur"]}
              rules={[
                { required: !item.notRequired, message: "This field is required" },
                {
                  validator: (rule, value) => {
                    if (item.checkDuplicate) {
                      return validateFieldDuplicate(rule, value, allItems, mainField, mode, currentItem);
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validator: (rule, value) => {
                    if (item.email) {
                      return validateEmail(rule, value);
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validator: (rule, value) => {
                    return validateAlphabet(rule, value);
                  },
                },
                {
                  validator: (rule, value) => {
                    if (item.password) {
                      return validatePassword(rule, value);
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validator: (rule, value) => {
                    return validateMaxLength(rule, value, item.maxLength);
                  },
                },
                {
                  validator: (rule, value) => {
                    if (item.name === "phone") {
                      return validatePhone(rule, value);
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              {renderElements(item)}
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
}
