import React from "react";
import "antd/dist/antd.css";
import PropTypes from "prop-types";
import { Modal } from "antd";

export default function ModalComponent({ title, text, isModalVisible, modalSubmitOk, onModalCancelHandler, footer }) {
  return (
    <Modal
      maskClosable={false}
      title={title}
      centered
      visible={isModalVisible}
      onOk={modalSubmitOk}
      onCancel={onModalCancelHandler}
      footer={footer}
    >
      <p>{text}</p>
    </Modal>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  onModalCancelHandler: PropTypes.func,
};
