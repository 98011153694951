import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setStartDataViewerAction,
  updateViwerDataAction,
  finishCallAction,
  updateCallActivityAction,
  clearViewerAction,
} from "../../redux/actions/scriptViewerPageActions";
import { callActivityFormObject, getAllUrlParams, getDateInMilliseconds } from "../../utils/helpers";
import { closeSliderMenuAction } from "../../redux/actions/commonActions";
import EndCallModal from "./EndCallModal";
import { Spin } from "antd";
import API from "../../services/apiService";
import { format } from "date-fns";
import { Row } from "antd";
import QuestionsBlock from "./QuestionsBlock";
import ObjectionsBlock from "./ObjectionsBlock";
import ScriptBlock from "./ScriptBlock";
import { Prompt } from "react-router";
import usePersistedState from "../../hooks/usePersistedState";
import EndCallQuestionModal from "./EndCallQuestionModal";
import { clearStorageData, transformQuestions, FORMAT_DATE } from "../../utils/helpers";
import BreathBar from "../../components/BreathBar/BreathBar";
import NavigationSection from "./NavigationSection";
import axios from "axios";
import notificationAlert from "../../utils/notificationAlert";
import "./_script-viewer-page.less";

function ScriptViewerPage(props) {
  const {
    allScriptStages,
    currentScript,
    questions,
    currentStage,
    isLoading,
    prevDisabled,
    nextDisabled,
    currentCall,
    dispatch,
    loggedUser,
    history,
  } = props;

  const [selectedObjection, setSelectedObjection] = useState({}); //current selected objection
  const [chosenObjections, setChosenObjections] = usePersistedState("chosenObjections", []); //already chosen objections

  const [isEndReasonVisible, setEndReasonVisible] = useState(false); //modal that appears when click end call
  const [isEndCallQuestionVisible, setEndCallQuestionVisible] = useState(false); //modal that appears after end call modal in case if question isnt empty

  useEffect(() => {
    //if call started first time get data from server, if not get from local storage (redux persist)

    dispatch(setStartDataViewerAction(currentStage, loggedUser, history));
    dispatch(closeSliderMenuAction());
    return () => {
      clearStorageData("currentCall", "answersList", "highlightData", "chosenObjections");
      history.replace({ pathname: "/", search: "", state: { isActive: true } });
      dispatch(clearViewerAction());
    };
  }, []);

  const nextStageHandler = () => {
    dispatch(
      updateCallActivityAction(
        callActivityFormObject({
          allScriptStages,
          currentCall,
          currentStage,
          itemInfo: "Next",
          itemType: allScriptStages[currentStage].name,
          itemId: allScriptStages[currentStage].id,
        })
      )
    );

    dispatch(
      updateViwerDataAction({
        nextDisabled: currentStage + 1 === allScriptStages.length - 1,
        prevDisabled: false,
        currentStage: currentStage + 1,
        questions: allScriptStages[currentStage + 1].questions,
      })
    );
  };

  const previousStageHandler = () => {
    dispatch(
      updateCallActivityAction(
        callActivityFormObject({
          allScriptStages,
          currentCall,
          currentStage,
          itemInfo: "Previous",
          itemType: allScriptStages[currentStage].name,
          itemId: allScriptStages[currentStage].id,
        })
      )
    );
    dispatch(
      updateViwerDataAction({
        prevDisabled: currentStage - 1 === 0,
        nextDisabled: false,
        currentStage: currentStage - 1,
        questions: allScriptStages[currentStage - 1].questions,
      })
    );
  };

  const handledObjectionHandler = () => {
    dispatch(
      updateCallActivityAction(
        callActivityFormObject({
          allScriptStages,
          currentCall,
          currentStage,
          itemInfo: "ObjectionHandled",
          itemType: selectedObjection.type,
          itemId: selectedObjection.id,
        })
      )
    );
    let updatedChosenObjection = Object.assign([], chosenObjections);
    updatedChosenObjection[currentStage] = selectedObjection;
    setChosenObjections(updatedChosenObjection);
    setSelectedObjection({});
  };

  const backToScriptHandler = () => {
    dispatch(
      updateCallActivityAction(
        callActivityFormObject({
          allScriptStages,
          currentCall,
          currentStage,
          itemInfo: "Back to Script",
          itemType: selectedObjection.type,
          itemId: selectedObjection.id,
        })
      )
    );
    setSelectedObjection({});
  };

  const closeEndReasonModal = () => setEndReasonVisible(false);

  const finishCurrentCall = async (endReason) => {
    const currentCallActivitiesWithLocalDates = currentCall.callActivities.map((activity) => {
      return { ...activity, date: activity.date };
    });

    const endCallActivity = callActivityFormObject({
      allScriptStages,
      currentCall,
      currentStage,
      itemInfo: "EndCall",
      itemType: allScriptStages[currentStage].name,
      itemId: allScriptStages[currentStage].id,
    });

    await API.post("call-activity/create", [...currentCallActivitiesWithLocalDates, endCallActivity]);

    await API.patch(`sales-call/end/${currentCall.id}`, {
      endDateTime: getDateInMilliseconds(new Date()),
      endReason: endReason.name,
      endReasonCategory: endReason.category,
      salesValue: endReason.salesValue,
      cash: endReason.cash,
      scriptId: currentScript.id,
      yesType: currentScript.saleType,
    });

    const answersList = JSON.parse(localStorage.getItem("answersList"));

    const transformedAnswers = transformQuestions(answersList);
    // console.log("Answers List Transform", transformedAnswers);
    // console.log("Call List Transform", currentCall);

    if (currentCall) {
      const externalParams = getAllUrlParams(history.location.search);
      const callActivityObject = {
        answers: transformedAnswers,
        callInfo: {
          startDate: currentCall.startDateTime,
          endDate: format(new Date(), FORMAT_DATE),
          endReason: endReason,
        },
        operator: { name: loggedUser.name, email: loggedUser.email },
        submittedTime: format(new Date(), FORMAT_DATE),
        lead: (currentCall && currentCall.lead) || null,
        ...externalParams,
      };
      try {
        console.log("Sending Webhook: ", currentCall.companyId, callActivityObject);
        await axios.post(`/api/webhook/send/${currentCall.companyId}`, callActivityObject);
      } catch (e) {
        notificationAlert({
          type: "warning",
          description: "Webhook request failed",
        });
      }
    }
    dispatch(
      finishCallAction({
        endReason,
        endDateTime: format(new Date(), FORMAT_DATE),
      })
    );

    clearStorageData("chosenObjections", "answeredQuestion", "highlightData", "currentCall");
    setEndReasonVisible(false);
    setEndCallQuestionVisible(true);
  };

  useEffect(() => {
    const replaceHistory = () => history.replace({ pathname: "/", search: "", state: { isActive: true } });
    window.addEventListener("popstate", replaceHistory);
  }, []);

  const getSectionMode = () => {
    if (selectedObjection && Object.entries(selectedObjection).length) {
      return "objectionScript";
    }
    return "textScript";
  };

  if (isLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  if (!allScriptStages.length) {
    return (
      <div className="centered_wrapper">
        {" "}
        This script is empty{" "}
        <span onClick={() => history.goBack()} style={{ color: "#1890ff" }}>
          Back home
        </span>
      </div>
    );
  }

  const renderScriptContent = (stage) => {
    if (getSectionMode() === "textScript") {
      return (
        <ScriptBlock
          key={stage.id}
          type={"textScript"}
          title={stage.name}
          value={stage.script}
          currentStage={currentStage}
          previousStageHandler={previousStageHandler}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          nextStageHandler={nextStageHandler}
          handledObjectionHandler={handledObjectionHandler}
          backToScriptHandler={backToScriptHandler}
          currentCall={currentCall}
        />
      );
    }
    return (
      <ScriptBlock
        key={selectedObjection.id}
        title={allScriptStages[currentStage].name}
        objectionTitle={selectedObjection.name}
        type={"objectionScript"}
        value={selectedObjection.script}
        currentStage={currentStage}
        previousStageHandler={previousStageHandler}
        prevDisabled={prevDisabled}
        nextDisabled={nextDisabled}
        nextStageHandler={nextStageHandler}
        handledObjectionHandler={handledObjectionHandler}
        backToScriptHandler={backToScriptHandler}
        currentCall={currentCall}
      />
    );
  };

  return (
    <>
      <div className="script__viewer">
        <div className="breath_section">
          <BreathBar progress={"50%"} height={"22px"} title={"Take a breath"} />
        </div>
        <div className="script__viewer-inner-content">
          <Row className="main_row">
            <ObjectionsBlock
              selectedObjection={selectedObjection}
              setSelectedObjection={setSelectedObjection}
              objections={allScriptStages[currentStage].objections}
              currentStage={currentStage}
              chosenObjections={chosenObjections}
              dispatch={dispatch}
              currentCall={currentCall}
              allScriptStages={allScriptStages}
            />
            {allScriptStages.map((stage, stageIndex) => {
              if (currentStage === stageIndex) return renderScriptContent(stage);
              return null;
            })}
            <QuestionsBlock allScriptStages={allScriptStages} questions={questions} currentStage={currentStage} />
          </Row>
        </div>
        <NavigationSection setEndReasonVisible={setEndReasonVisible} />
      </div>
      {isEndReasonVisible ? (
        <EndCallModal
          title={"End call/Choose reason for the end of the call"}
          isVisible={isEndReasonVisible}
          endReasonItems={allScriptStages[currentStage].endReasons}
          finish={finishCurrentCall}
          closeModal={closeEndReasonModal}
        />
      ) : (
        ""
      )}
      {isEndCallQuestionVisible ? (
        <EndCallQuestionModal
          dispatch={dispatch}
          companyId={currentCall.companyId}
          title={"End call/Check and edit questions after call"}
          isVisible={isEndCallQuestionVisible}
        />
      ) : (
        ""
      )}
      <Prompt
        when={!currentCall.endDateTime}
        message={() => "The call has not ended yet, do you really want to leave the page?"}
      />
    </>
  );
}

function mapStateToProps(state) {
  const {
    allScriptStages,
    currentScript,
    questions,
    currentStage,
    isLoading,
    prevDisabled,
    nextDisabled,
    currentCall,
  } = state.viewer;
  const { user: loggedUser } = state.common;

  return {
    allScriptStages,
    currentScript,
    questions,
    currentStage,
    isLoading,
    prevDisabled,
    nextDisabled,
    currentCall,
    loggedUser,
  };
}

export default connect(mapStateToProps)(ScriptViewerPage);
