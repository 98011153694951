import React from "react";
import { Select, Tooltip } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";

//Select with tooltip
export default function MeetingPlanMembersSelect({
  value,
  selectTeamMembers,
  systemUsers,
  unselectTeamMember,
  className,
}) {
  return (
    <Select
      placeholder="Select members"
      dropdownClassName={"select_dropdown"}
      className={className}
      mode="multiple"
      value={value}
      onSelect={selectTeamMembers}
      showArrow
      onDeselect={unselectTeamMember}
      filterOption={(value, data) => {
        if (data.children?.props?.children.toLowerCase().includes(value.toLowerCase())) return data.children;
      }}
    >
      {systemUsers && systemUsers.length
        ? sortByName(systemUsers, "name").map((user) => (
            <Select.Option key={user.id} value={user.id}>
              <Tooltip title={`${user.name} (${user.email})`}>{user.name}</Tooltip>
            </Select.Option>
          ))
        : null}
    </Select>
  );
}

MeetingPlanMembersSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  selectTeamMembers: PropTypes.func,
  systemUsers: PropTypes.arrayOf(PropTypes.object),
  unselectTeamMember: PropTypes.func,
  className: PropTypes.string,
};
