import React from "react";
import Button from "antd/lib/button";
import PropTypes from "prop-types";
import { Typography } from "antd";
import "./_userBrnGroup.less";

const { Text } = Typography;

export default function UserButtonGroup({ data, position, title }) {
  const getElementType = (btn) =>
    btn.isActive ? btn.themeActive : btn.isActiveSelected ? btn.themeSelected : btn.theme;

  return (
    <div className={`user_btn_group ${position === "vertical" ? "vertical" : ""}`}>
      {title && <h3 className="title">{title}</h3>}
      {data.map((btn, index) => {
        return (
          <Button
            className={`btn ${!btn.visible && "hide"}`}
            key={index}
            type={getElementType(btn)}
            onClick={btn.action}
          >
            <Text className="btn_text">{btn.name}</Text>
          </Button>
        );
      })}
    </div>
  );
}

UserButtonGroup.propTypes = {
  position: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
