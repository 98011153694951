import React from "react";
import { Select } from "antd";
import { sortByName } from "../../../utils/helpers";
import PropTypes from "prop-types";

function StaffDetailFilter({ loading, value, allData, onChange, optionField, isDisabled }) {
  return (
    <Select
      mode="multiple"
      className="filter"
      disabled={isDisabled}
      placeholder="Filter calls"
      showSearch
      loading={loading || false}
      onChange={onChange}
      value={value}
    >
      {allData &&
        allData.length &&
        sortByName(allData, optionField).map((entity, index) => {
          return (
            <Select.Option key={index} value={entity[optionField]}>
              {entity[optionField]}
            </Select.Option>
          );
        })}
    </Select>
  );
}

StaffDetailFilter.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.array,
  allData: PropTypes.array,
  onChange: PropTypes.func,
  optionField: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default StaffDetailFilter;
