import React from "react";
import Button from "antd/lib/button";
import PropTypes from "prop-types";
import "./_button-group.less";

export default function ButtonGroup({ data, type, position, title, className }) {
  return (
    <div className={`btn_group ${position === "vertical" ? "vertical" : ""}`}>
      {title && <h3 className="title">{title}</h3>}
      {data.map((btn, index) => (
        <Button
          className={`btn ${!btn.visible && "hide"} ${className ? className : ""}`}
          key={index}
          type={btn.isActive ? "dark" : btn.isActiveSelected ? "orange" : type || "primary"}
          onClick={btn.action}
        >
          {btn.name}
        </Button>
      ))}
    </div>
  );
}

ButtonGroup.defaultProps = {
  className: "",
};

ButtonGroup.propTypes = {
  type: PropTypes.string,
  position: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
