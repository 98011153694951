import React from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

export default function FilterDropdownComponent({
  dataIndex,
  setSelectedKeys,
  selectedKeys,
  confirm,
  onSearch,
  inputRef,
}) {
  const handleSearch = (searchText, confirmHandler, searchedColumn) => {
    confirmHandler();
    onSearch({ searchText: searchText[0], searchedColumn });
  };

  const onChange = ({ target: { value } }) => {
    setSelectedKeys(value ? [value] : []);
  };

  return (
    <div style={{ padding: "20px 10px 15px 10px" }}>
      <Input
        ref={inputRef}
        placeholder={`Search ${dataIndex}`}
        onChange={(event) => onChange(event)}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        value={selectedKeys[0]}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 120 }}
        >
          Search
        </Button>
      </Space>
    </div>
  );
}

FilterDropdownComponent.propTypes = {
  dataIndex: PropTypes.string,
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  onSearch: PropTypes.func,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};
