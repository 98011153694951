import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import { Popover } from "antd";
import PropTypes from "prop-types";
import "./_dots.less";

const DotsComponent = ({ stats, onChange, activeDot, title }) => {
  const calculateDotColor = (stat) => {
    if (stat) {
      if (stat.salesKPI === "GREEN") return "green";
      if (stat.salesKPI === "RED") return "red";
      if (stat.salesKPI === "YELLOW") return "yellow";
      return "grey";
    }
  };

  const content = (stat) => {
    return (
      <div>
        <p>Sales KPI: {stat && stat.salesKPI}</p>
        <p>Pitch KPI: {stat && stat.pitchKPI}</p>
        <p>Conversation KPI: {stat && stat.conversationKPI}</p>
      </div>
    );
  };

  const sortStatsByDate = (stats) => stats.sort((prev, next) => new Date(prev.date) - new Date(next.date));

  const formatDate = (stat) => (stat && stat.date ? format(parseISO(stat.date), "d/M/y") : "-");

  return (
    <>
      {stats.length ? (
        <div className="dots_component">
          <h3>{title}</h3>
          <div className="circle_list">
            {sortStatsByDate(stats).map((stat, dotIndex) => {
              return (
                <Popover key={dotIndex} content={content(stat)} title={formatDate(stat)}>
                  <div
                    onClick={onChange ? () => onChange({ selectedStat: stat, dotDate: stat.date }) : null}
                    className={`circle_item ${calculateDotColor(stat)}`}
                  >
                    {activeDot && activeDot.id === stat.id ? <CheckOutlined className="activeDotIcon" /> : ""}
                  </div>
                </Popover>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

DotsComponent.defaultProps = {
  title: "Your last 7 days:",
  activeDot: {},
};

DotsComponent.propTypes = {
  stats: PropTypes.array,
  onChange: PropTypes.func,
  activeDot: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    guidance: PropTypes.string,
    name: PropTypes.string,
    hoursDialing: PropTypes.number,
    sales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    companyTimezone: PropTypes.string,
    //and other KPI
  }),
  title: PropTypes.string,
};

export default DotsComponent;
