import React, { useCallback, useMemo, useState } from "react";
import ChipComponent from "../../../components/Chip/Chip";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { Button } from "antd";
import { addNewReasonsAction, deleteReasonAction, editReasonAction } from "../../../redux/actions/scriptPageActions";
import { end_categories } from "../../../utils/mocks";
import PropTypes from "prop-types";
import "../_script-page.less";

export default function ScriptObjectionSection({ currentStage, currentPage, dispatch }) {
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedReason, setSelectedObjection] = useState(0);

  const editReason = ({ name, category }) => {
    dispatch(editReasonAction({ name, category, currentPage, reasonIdx: selectedReason }));
  };

  const deleteReason = () => dispatch(deleteReasonAction({ reasonIdx: selectedReason, currentPage }));

  const createNewReason = ({ name, category }) => {
    dispatch(
      addNewReasonsAction({
        name,
        category,
        currentPage,
        reasonIdx: currentStage.endReasons.length,
      })
    );
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewReason(value);
    } else if (modalMode === "edit") {
      await editReason(value);
    } else if (modalMode === "delete") {
      await deleteReason();
    }
    setModalVisible(false);
  };

  const selectCurrentHandler = (value) => {
    setSelectedObjection(value);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const reasonsRender = useCallback(() => {
    return currentStage.endReasons.map((reason, index) => {
      return (
        <ChipComponent
          key={index}
          editHandler={() => onModalOpen("edit")}
          deleteHandler={() => onModalOpen("delete")}
          selectCurrentHandler={() => selectCurrentHandler(index)}
          text={reason.name}
        />
      );
    });
  }, [currentStage.endReasons, selectedReason]);

  const modals = [
    {
      title: "Delete",
      text: `Delete «${
        currentStage && currentStage.endReasons[selectedReason] && currentStage.endReasons[selectedReason].name
      }» reason`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit reason", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new reason", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type reason name",
          elementType: "input",
          checkDuplicate: true,
        },
        {
          name: "category",
          placeholder: "Choose reason category",
          elementType: "select",
          elementList: end_categories,
          valuePath: "cat",
        },
      ];
    },
    []
  );

  return (
    <div className="section">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"name"}
          allItems={currentStage && currentStage.endReasons}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={currentStage.endReasons[selectedReason]}
        />
      ) : null}
      <div className="config_panel">
        <h3 className="config_title">Reasons:</h3>
        <Button type="primary" className="button" onClick={() => onModalOpen("add")}>
          Add reason
        </Button>
      </div>
      {currentStage && currentStage.endReasons && currentStage.endReasons.length ? (
        <div className="content_panel">{reasonsRender()}</div>
      ) : (
        <div>There are no reasons yet...</div>
      )}
    </div>
  );
}

ScriptObjectionSection.propTypes = {
  currentStage: PropTypes.object,
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
};
