/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Table, Space, Button, Form, notification, PageHeader } from "antd";
import API from "../../services/apiService";
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";

//TODO
export default function Guidance() {
  const { Column } = Table;

  let [editing, setEditing] = useState(false);
  let [editItem, setEditItem] = useState(null);
  let [editIndex, setEditIndex] = useState(null);
  let [data, setData] = useState([]);
  let [rawData, setRawData] = useState(null);
  let [companies, setCompanies] = useState([]);
  let [loading, setLoading] = useState(true);
  let [selectedRowKeys, setSelectedRows] = useState([]);

  let [filters, setFilters] = useState([]);

  const onSelectChange = (selected) => setSelectedRows(selected);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableChange = (pagination, filterz, sorter, extra) => {
    if (extra.action === "filter") {
      console.log("Setting Filter", filterz);
      filters = filterz;
      setFilters(filterz);
      localStorage.setItem("guidance-filter", JSON.stringify(filterz));
    }
  };

  const title = "Edit Training";
  const [form] = Form.useForm();

  const openNotification = (message, type, placement) => {
    notification[type]({
      message: message,
      description: "",
      duration: 3,
      placement,
    });
  };

  const newRecord = () => {
    form.resetFields();
    setEditItem({});
    setEditing(true);
  };

  const doSubmit = async () => {
    if (selectedRowKeys.length === 1 || selectedRowKeys.length === 0) {
      return doSaveSingle(editItem);
    }
    doSaveMultiple();
  };

  const doSaveMultiple = () => selectedRowKeys.forEach((id) => {});

  const doSaveSingle = async (item) => {
    //Do save
    console.log("Item ", item);
    const object = Object.assign(item, form.getFieldsValue());
    console.log("Edit Item", object);
    try {
      let objectDb = await API.post("training/save", object);
      if (object.hasOwnProperty("id")) {
        console.log("Splicing");
        data.splice(editIndex, 1, object);
        setData(data);
      } else {
        console.log("Adding");
        console.log("Data", data);
        setData([...data, objectDb]);
      }

      setEditing(false);
      openNotification("Save Successful", "success", "topRight");
    } catch (err) {
      openNotification("Save Failed", "error", "topRight");
      console.warn(err);
      return false;
    }
  };

  const doReset = () => {
    setEditing(false);
  };

  const toggleModal = () => {};

  const loadData = async () => {
    const resp = await API.get("training/list");
    setLoading(false);
    return resp;
  };

  /* const search = async (value) => {
    //String filter
    if (value !== '') {
      setData(rawData.filter((it) => it.phase.includes(value) || it.title.includes(value)));
    } else {
      setData(rawData);
    }
  };*/

  const modalProps = { onCancel: doReset, onOk: doSubmit };

  /*  const searchConfig = { labelWidth: 'auto' };

  const menu = (
    <Menu>
      <Menu.Item key='1'>1st item</Menu.Item>
      <Menu.Item key='2'>2nd item</Menu.Item>
      <Menu.Item key='3'>3rd item</Menu.Item>
    </Menu>
  );*/

  const editSelected = () => {
    setEditing(true);
    if (selectedRowKeys.length == 1) {
      console.log("Selected Row Keys: ", selectedRowKeys);
      let editItem = data.find((e) => e.id === selectedRowKeys[0]);
      setEditItem(editItem);
      form.setFieldsValue(editItem);
    } else {
      form.setFieldsValue(null);
    }
  };

  useEffect(async () => {
    console.log("Loading Data");
    setLoading(true);
    let guidanceData = loadData();
    let companyData = API.get("company/all");

    Promise.all([guidanceData, companyData]).then(([data, companies]) => {
      setData(data);
      setRawData(data);
      setCompanies(companies);
    });

    try {
      setFilters(JSON.parse(localStorage.getItem("guidance-filters")));
    } catch (err) {
      setFilters(null);
    }
  }, []);

  return (
    <Space direction="vertical">
      <PageHeader
        ghost={true}
        onBack={() => window.history.back()}
        title="Training"
        subTitle="Adjust Iron Cage Guidance"
        extra={[
          <Button key="3" disabled={!selectedRowKeys.length} onClick={editSelected}>
            Edit
          </Button>,
          <Button key="2">Clone</Button>,
          <Button key="1" type="primary" onClick={newRecord}>
            New
          </Button>,
        ]}
      />

      <Table
        dataSource={data}
        pagination={false}
        loading={loading}
        rowKey={(rec) => rec.id}
        rowSelection={rowSelection}
        onChange={tableChange}
      >
        <Column title="Phase" dataIndex="callPhase" sorter={(a, b) => a.callPhase.localeCompare(b.callPhase)} />
        <Column title="End Reason" dataIndex="endReason" sorter={(a, b) => a.endReason.localeCompare(b.endReason)} />
        <Column title="Title" dataIndex="title" sorter={(a, b) => a.title.localeCompare(b.title)} />
        <Column title="Guidance" dataIndex="guidance" sorter={(a, b) => a.guidance.localeCompare(b.guidance)} />
        <Column title="URL" dataIndex="trainingUrl" sorter={(a, b) => a.trainingUrl.localeCompare(b.trainingUrl)} />
        <Column title="Company" dataIndex={["Company", "name"]} />
      </Table>

      <ModalForm
        form={form}
        visible={editing}
        title={title}
        onFinish={doSubmit}
        onReset={doReset}
        modalProps={modalProps}
        initialValues={{}}
        onVisibleChange={toggleModal}
      >
        <ProFormSelect
          name="callPhase"
          label="Call Phase"
          valueEnum={{
            intro: "Intro",
            probe: "Probe",
            breakthrough: "Breakthrough",
            pitch: "Pitch",
          }}
          placeholder="Select Call Phase"
        />
        <ProFormText
          name="title"
          label="Title"
          placeholder="Title"
          rules={[{ required: selectedRowKeys.length === 1, message: "Title is required" }]}
        />
        <ProFormTextArea name="guidance" label="Guidance" placeholder="Guidance text" />
        <ProFormText name="trainingUrl" label="URL" placeholder="Training URL" />
      </ModalForm>
    </Space>
  );
}
