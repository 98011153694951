import React from "react";
import Button from "antd/lib/button";
import { PhoneOutlined } from "@ant-design/icons";

export default function StartScriptButton({ currentScript, startCallHandler }) {
  return (
    <div className="start-btn">
      <Button
        type={"orange"}
        icon={<PhoneOutlined className="icon_phone" />}
        onClick={startCallHandler}
        disabled={currentScript === undefined || (currentScript && !Object.keys(currentScript).length)}
      >
        Start call
      </Button>
    </div>
  );
}
