import React from "react";
import StaffDetailFilter from "./StaffDetailFilter";
import { setCallEndReasonFilters, updateCallEndReasons } from "../../../redux/actions/callsInfoPageActions";
import { Card, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

function StaffDetailReasonsStat() {
  const dispatch = useDispatch();
  const callEndReasonsFiltersActive = useSelector((state) => state.callsInfo.callEndReasonsFiltersActive);
  const callEndReasonsLoading = useSelector((state) => state.callsInfo.callEndReasonsLoading);
  const callEndReasonsFilters = useSelector((state) => state.callsInfo.callEndReasonsFilters);
  const callEndReasons = useSelector((state) => state.callsInfo.callEndReasons);

  const filterCallEndReasons = (filters) => {
    const filtersObj =
      filters &&
      filters.length &&
      filters.map((filterName) => {
        return callEndReasonsFilters.find((reason) => reason.type === filterName);
      });

    dispatch(setCallEndReasonFilters(filtersObj));
    dispatch(updateCallEndReasons(new Date()));
  };

  const transformFilters = (filters) => {
    return (filters && filters.length && filters.map((filter) => filter.type)) || [];
  };

  const sortByCount = (callEndReasons) => {
    return callEndReasons.sort(function (currentReason, prevReason) {
      return prevReason["value"] - currentReason["value"];
    });
  };

  const getContent = () => {
    if (callEndReasonsLoading) {
      return (
        <div className="block_spinner">
          <Spin size="large" />
        </div>
      );
    }
    return callEndReasons && callEndReasons.length
      ? sortByCount(callEndReasons).map((call, index) => {
          return (
            <Card.Grid className="card" key={index}>
              <div className="name">{call.type}</div>
              <div className="count">{call.value}</div>
            </Card.Grid>
          );
        })
      : null;
  };

  return (
    <div className="reason_stat">
      <Card
        className="cards"
        title="Call end reasons"
        extra={
          <div className="extra_container">
            <StaffDetailFilter
              value={transformFilters(callEndReasonsFiltersActive) || []}
              allData={callEndReasonsFilters}
              optionField={"type"}
              onChange={filterCallEndReasons}
            />
          </div>
        }
      >
        {getContent()}
      </Card>
    </div>
  );
}

export default StaffDetailReasonsStat;
