import React, { useState, useCallback, useMemo } from "react";
import { Button, Dropdown, Menu, Upload } from "antd";
import {
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
  UserAddOutlined,
  ReloadOutlined,
  AuditOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import {
  resetLeadsFilters,
  uploadLeads,
  deleteAllLeads,
  createLead,
  updateLeadTable,
  scriptLeadsAssign,
  userLeadsAssign,
  funnelLeadsAssign,
  setSelectedLeads,
} from "../../../redux/actions/leadsActions";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/Popup/Popup";
import LeadForm from "../LeadForm/LeadForm";
import DeleteForm from "../LeadForm/DeleteForm";
import FormWithSelect from "../LeadForm/FormWithSelect";
import useDelayUnmount from "../../../hooks/useDelayUnmount";
import FunnelAssignForm from "../LeadForm/FunnelAssignForm";
import notificationAlert from "../../../utils/notificationAlert";
import UserAssignForm from "../LeadForm/UserAssignForm";
import "./_leadPageConfigPanel.less";

export default function LeadsPageConfigPanel() {
  const dispatch = useDispatch();
  const selectedLeads = useSelector((state) => state.leads.selectedLeads);
  const selectedCompany = useSelector((state) => state.leads.selectedCompany);
  const allScripts = useSelector((state) => state.leads.allScripts);
  const allUsers = useSelector((state) => state.leads.allUsers);
  const filteredValue = useSelector((state) => state.leads.filters.filteredValue);
  const funnels = useSelector((state) => state.leads.funnels);
  const currentPageLeads = useSelector((state) => state.leads.currentPageLeads);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");

  const shouldRenderChild = useDelayUnmount(isModalOpen, 500); //the best solution to save animation when close modal
  const [currentLead, setCurrentLead] = useState(null);

  const uploadLeadsHandler = async ({ file }) => dispatch(uploadLeads(file, selectedCompany));

  const onModalOpen = (mode, item) => {
    setModalMode(mode);
    setModalOpen(true);
    setCurrentLead(item);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setModalMode("");
  };

  const deleteAllLeadsHandler = async () => await dispatch(deleteAllLeads()).then(() => onModalClose());

  const resetFiltersHandler = () => {
    dispatch(resetLeadsFilters());
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const createNewLead = async (newLeadData) => {
    const leadsScripts = newLeadData.leadsScripts && [
      {
        name: newLeadData.leadsScripts,
        salesPersonId: allScripts.find((script) => script.name === newLeadData.leadsScripts).id,
      },
    ];

    const leadsSalesPersons = newLeadData.leadsSalesPersons && [
      {
        name: newLeadData.leadsSalesPersons,
        salesPersonId: allUsers.find((user) => user.name === newLeadData.leadsSalesPersons).id,
      },
    ];

    const getFunnel = selectedCompany.funnels.find((funnel) => funnel.name === newLeadData.funnel);
    const getFunnelStage = getFunnel && getFunnel.funnelStages.find((stage) => stage.name === newLeadData.funnelStage);

    await dispatch(
      createLead({
        ...newLeadData,
        leadsScripts,
        leadsSalesPersons,
        funnelId: getFunnel ? getFunnel.id : null,
        funnelStageId: getFunnelStage ? getFunnelStage.id : null,
        companyId: selectedCompany.id,
      })
    );
    onModalClose();
    dispatch(setSelectedLeads([]));
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const scriptAssignHandler = async ({ scriptAssign }) => {
    const script = allScripts.find((script) => script.name === scriptAssign);

    if (!script) {
      return notificationAlert({
        duration: 3,
        type: "error",
        description: "There is no script with that name",
      });
    }

    await dispatch(scriptLeadsAssign(script));
    dispatch(setSelectedLeads([]));
    onModalClose();
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const userAssignHandler = async ({ userAssign }) => {
    const user = allUsers.find((user) => user.id === userAssign);
    await dispatch(userLeadsAssign(user));
    onModalClose();
    dispatch(setSelectedLeads([]));
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const funnelAssignHandler = async ({ funnel, funnelStage }) => {
    funnel = funnels.find((fnl) => fnl.name === funnel);
    funnelStage = funnel.funnelStages.find((el) => el.name === funnelStage);
    await dispatch(funnelLeadsAssign(currentLead, funnel, funnelStage));
    onModalClose();
    dispatch(setSelectedLeads([]));
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const removeDataDuplicates = useCallback(
    (data, assignField, assignFieldId) => {
      let result = data.filter(({ id }) => {
        return selectedLeads.every((selectedLeadId) => {
          let currentLeadUsers = currentPageLeads.find((lead) => lead.id === selectedLeadId)[assignField];
          return currentLeadUsers.some((s) => s[assignFieldId] === id);
        });
      });

      return result[0] && result[0].name;
    },
    [allScripts, allUsers, selectedLeads]
  );

  const popupForms = useMemo(
    () => [
      {
        name: "create",
        component: <LeadForm onCancel={onModalClose} onSubmit={createNewLead} />,
        title: "Create lead",
      },
      {
        name: "deleteAll",
        component: (
          <DeleteForm
            onCancel={onModalClose}
            onSubmit={deleteAllLeadsHandler}
            title={"Are you sure you want to delete selected leads?"}
          />
        ),
        title: "Delete leads",
      },
      {
        name: "scriptAssign",
        component: (
          <FormWithSelect
            onCancel={onModalClose}
            onSubmit={scriptAssignHandler}
            name={"scriptAssign"}
            currentValue={removeDataDuplicates(allScripts, "leadsScripts", "scriptId")}
            data={allScripts}
            placeholder={"Choose script"}
          />
        ),
        title: "Script Assign",
      },
      {
        name: "userAssign",
        component: (
          <UserAssignForm
            onCancel={onModalClose}
            onSubmit={userAssignHandler}
            currentValue={removeDataDuplicates(allUsers, "leadsSalesPersons", "salesPersonId")}
            name={"userAssign"}
            data={allUsers}
            placeholder={"Choose user"}
          />
        ),
        title: "User assign",
      },
      {
        name: "funnelAssign",
        component: <FunnelAssignForm onCancel={onModalClose} onSubmit={funnelAssignHandler} name={"funnelAssign"} />,
        title: "Funnel Assign",
      },
    ],
    [allScripts, allUsers, selectedLeads]
  );

  const getPopupForm = useCallback((modalMode) => popupForms.find((form) => form.name === modalMode)?.component, [
    allScripts,
    allUsers,
    selectedLeads,
  ]);

  const getModalTitle = useCallback((modalMode) => popupForms.find((form) => form.name === modalMode)?.title, [
    allScripts,
    allUsers,
    selectedLeads,
  ]);

  const actionsMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => onModalOpen("create")} icon={<PlusOutlined />}>
        Add lead
      </Menu.Item>
      <Menu.Item key="2" icon={<UploadOutlined />}>
        <Upload accept={".xlsx, .xls, .csv, .ods"} customRequest={uploadLeadsHandler} showUploadList={false}>
          Click to upload
        </Upload>
      </Menu.Item>
      <Menu.Item key="3" icon={<ReloadOutlined />} disabled={!filteredValue} onClick={resetFiltersHandler}>
        Reset filters
      </Menu.Item>
      <Menu.Item
        key="4"
        icon={<MinusOutlined />}
        disabled={!selectedLeads.length}
        onClick={() => onModalOpen("deleteAll")}
      >
        Delete chosen leads
      </Menu.Item>
      <Menu.Item
        key="5"
        icon={<DiffOutlined />}
        disabled={!selectedLeads.length}
        onClick={() => onModalOpen("scriptAssign")}
      >
        Assign script to leads
      </Menu.Item>
      <Menu.Item
        key="6"
        icon={<UserAddOutlined />}
        disabled={!selectedLeads.length}
        onClick={() => onModalOpen("userAssign")}
      >
        Assign user to leads
      </Menu.Item>
      <Menu.Item
        key="7"
        icon={<AuditOutlined />}
        disabled={!selectedLeads.length}
        onClick={() => onModalOpen("funnelAssign")}
      >
        Assign funnel to lead
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="lead_config-section">
      <div className="setting_config">
        <Dropdown overlay={actionsMenu} trigger={["click"]}>
          <Button className={"btn"} type="primary">
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      {(shouldRenderChild && (
        <Popup
          title={getModalTitle(modalMode)}
          isModalVisible={isModalOpen}
          footer={null}
          className={"without_footer"}
          modalCancel={onModalClose}
        >
          {getPopupForm(modalMode)}
        </Popup>
      )) ||
        null}
    </div>
  );
}
