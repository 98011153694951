import React from "react";
import { Checkbox, Col, Row } from "antd";
import PropTypes from "prop-types";

function StatsScheduleDaysSection({ days, onDaysPickHandler }) {
  return (
    <Row className="days_row" gutter={[10, 30]}>
      <Col span={24} className="daysRow_title">
        <h2>Days:</h2>
      </Col>
      {days.map((day) => {
        return (
          <Col key={day.day} xs={12} sm={10} md={7} lg={7} xl={6}>
            <Checkbox
              value={day.day}
              onChange={({ target: { checked } }) => onDaysPickHandler(day.day, checked)}
              checked={day.isChecked}
              className="checkbox"
            >
              {day.day}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
}

StatsScheduleDaysSection.propTypes = {
  days: PropTypes.array,
  onDaysPickHandler: PropTypes.func,
};

export default StatsScheduleDaysSection;
