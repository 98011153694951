import { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Table } from "antd";
import tableConfig from "./LeadPageConfig";
import {
  setSelectedLeads,
  updatePagination,
  updateSorter,
  editLead,
  deleteLead,
  updateLeadFilters,
  updateLeadTable,
  funnelLeadsAssign,
} from "../../../redux/actions/leadsActions";
import Popup from "../../../components/Popup/Popup";
import LeadForm from "../LeadForm/LeadForm";
import FormWithSelect from "../LeadForm/FormWithSelect";
import DeleteForm from "../LeadForm/DeleteForm";
import notificationAlert from "../../../utils/notificationAlert";
import useDelayUnmount from "../../../hooks/useDelayUnmount";
import FunnelAssignForm from "../LeadForm/FunnelAssignForm";
import "./_leadTable.less";

function LeadsTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCompany = useSelector((state) => state.leads.selectedCompany);
  const currentPageLeads = useSelector((state) => state.leads.currentPageLeads);
  const selectedLeads = useSelector((state) => state.leads.selectedLeads);
  const loggedUser = useSelector((state) => state.common.user);
  const pagination = useSelector((state) => state.leads.pagination);
  const sorter = useSelector((state) => state.leads.sorter);
  const isLoading = useSelector((state) => state.leads.isLoading);
  const allScripts = useSelector((state) => state.leads.allScripts);
  const filters = useSelector((state) => state.leads.filters);
  const funnels = useSelector((state) => state.leads.funnels);

  const [currentLead, setCurrentLead] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");

  const shouldRenderChild = useDelayUnmount(isModalOpen, 300); //the best solution to save animation when close modal

  const openModal = (mode, item) => {
    setCurrentLead(item);
    setModalMode(mode);
    setModalOpen(true);
  };

  const modalCancel = () => {
    setCurrentLead(null);
    setModalMode("");
    setModalOpen(false);
  };

  const startScriptAfterDialing = ({ leadsScripts, id: leadId }) => {
    let selectedScript = allScripts.find((script) => script.name === leadsScripts);

    if (!selectedScript) {
      return notificationAlert({
        duration: 5,
        type: "error",
        description: "Sorry. This script is no longer available",
      });
    }

    localStorage.setItem("currentCompanyName", selectedCompany.name);
    localStorage.setItem("selectedScript", selectedScript.name);

    setTimeout(() => {
      history.push({
        pathname: "/startScript",
        search: `?script_id=${selectedScript.id}&lead_id=${leadId}`,
      });
    }, 2000);
  };

  const startCallHandler = (data) => {
    const checkScriptAvailable = allScripts.find((script) => script.name === data.leadsScripts);

    if (!checkScriptAvailable) {
      return notificationAlert({
        duration: 5,
        type: "error",
        description: "Sorry. This script is no longer available",
      });
    }

    localStorage.setItem("currentCompanyName", selectedCompany.name);
    localStorage.setItem("selectedScript", checkScriptAvailable.name);

    history.push({
      pathname: "/startScript",
      search: `?script_id=${checkScriptAvailable.id}&lead_id=${currentLead.id}`,
    });
  };

  const setFilters = ({ searchText, searchedColumn }) => {
    dispatch(updateLeadFilters({ filteredValue: searchText, filteredField: searchedColumn }));
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const { getData, getColumns } = tableConfig({
    currentPageLeads,
    selectedCompany,
    openModal,
    startScriptAfterDialing,
    sorter,
    filters,
    setFilters,
    loggedUserRole: loggedUser.role,
  });

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      dispatch(updatePagination({ pagination }));

      return setTimeout(() => {
        dispatch(updateLeadTable({ isUpdated: new Date() }));
        window.scrollTo({ top: 0, behavior: "auto" });
      }, 300);
    }

    if (extra.action === "sort") {
      dispatch(updateSorter({ sorter, isUpdated: new Date() }));
    }
  };

  const chooseLeadHandler = (value) => dispatch(setSelectedLeads(value));

  const rowSelection =
    loggedUser.role !== "user"
      ? {
          type: "checkbox",
          onChange: chooseLeadHandler,
          selectedRowKeys: selectedLeads,
        }
      : "";

  const deleteLeadHandler = async () => await dispatch(deleteLead(currentLead.id)).then(() => modalCancel());

  const editLeadHandler = async (data) => await dispatch(editLead(data, currentLead)).then(() => modalCancel());

  const funnelAssignHandler = async ({ funnel, funnelStage }) => {
    funnel = funnels.find((el) => el.name === funnel);
    funnelStage = funnel.funnelStages.find((el) => el.name === funnelStage);
    await dispatch(funnelLeadsAssign(currentLead, funnel, funnelStage));
    modalCancel();
    dispatch(updateLeadTable({ isUpdated: new Date() }));
  };

  const popupForms = useMemo(
    () => [
      {
        name: "edit",
        component: <LeadForm onCancel={modalCancel} onSubmit={editLeadHandler} currentLead={currentLead} />,
        title: "Edit lead",
      },
      {
        name: "delete",
        component: (
          <DeleteForm
            onCancel={modalCancel}
            onSubmit={deleteLeadHandler}
            title={`Are you sure you want to delete "${currentLead?.name}" lead?`}
          />
        ),
        title: "Delete lead",
      },
      {
        name: "startCall",
        component: (
          <FormWithSelect
            onCancel={modalCancel}
            name="leadsScripts"
            currentValue={currentLead?.leadsScripts ? currentLead?.leadsScripts : null}
            data={allScripts.filter((script) => script.name === currentLead?.leadsScripts)}
            onSubmit={startCallHandler}
            placeholder={"Choose script"}
          />
        ),
        title: "Start script for selected lead?",
      },
      {
        name: "funnelAssign",
        component: <FunnelAssignForm onCancel={modalCancel} onSubmit={funnelAssignHandler} name={"funnelAssign"} />,
        title: "Funnel Assign",
      },
    ],
    [allScripts, currentLead]
  );

  const getPopupForm = useCallback((modalMode) => popupForms.find((form) => form.name === modalMode)?.component, [
    modalMode,
    currentLead,
  ]);

  const getModalTitle = useCallback((modalMode) => popupForms.find((form) => form.name === modalMode)?.title, [
    modalMode,
    currentLead,
  ]);

  return (
    <>
      <Table
        className="leadsTable"
        sticky={currentPageLeads && currentPageLeads.length ? { offsetHeader: 60 } : false}
        loading={selectedCompany?.name && isLoading}
        scroll={{ x: "auto" }}
        rowSelection={rowSelection}
        pagination={pagination}
        size="small"
        columns={getColumns}
        dataSource={getData}
        bordered={true}
        onChange={onTableChange}
      />
      {(shouldRenderChild && (
        <Popup
          title={getModalTitle(modalMode)}
          isModalVisible={isModalOpen}
          footer={null}
          className={"without_footer"}
          modalCancel={modalCancel}
        >
          {getPopupForm(modalMode, currentLead)}
        </Popup>
      )) ||
        null}
    </>
  );
}

export default LeadsTable;
