import React from "react";
import { Switch, Form, Button } from "antd";
import PropTypes from "prop-types";
import TimePicker from "../../../components/TimePicker/TimePicker";
import Multiselect from "../../../components/Multiselect/Multiselect";
import API from "../../../services/apiService";
import notificationAlert from "../../../utils/notificationAlert";
import { useDispatch } from "react-redux";
import { editCompanyAction } from "../../../redux/actions/adminPageActions";
import "./_reports.less";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function Reports({ allCompanies, currentCompany }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const sendReportConfig = async (data) => {
    const reportSettings = await API.put(`company/${currentCompany.id}/report-settings`, {
      userId:
        data.userId && data.userId.length
          ? data.userId.map((consultant) => {
              const resConsultant = currentCompany.consultants.find((cons) => cons.name === consultant);
              return resConsultant.id;
            })
          : [],
      time: data.time,
      enabled: !!data.enabled,
      ...DAYS.reduce((acc, day) => {
        acc[day.toLowerCase()] = false;
        return acc;
      }, {}),
      ...(data.days && data.days.length
        ? data.days.reduce((acc, day) => {
            acc[day.toLowerCase()] = true;
            return acc;
          }, {})
        : []),
    });

    const companyIndex = allCompanies.findIndex((company) => company.name === currentCompany.name);
    const updatedCompany = { ...currentCompany, reportSettings };
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
    notificationAlert({
      type: "success",
      description: "Report config successfully updated",
    });
  };

  const selectAllConsultants = () => {
    form.setFieldsValue({ userId: currentCompany.consultants.map((consultant) => consultant.name) });
  };

  const getConsultantNamesById = (users) => {
    if (users.length) return users.map((user) => user.name);
    return [];
  };

  return (
    <div className="reports_wrapper">
      <Form
        form={form}
        initialValues={{
          ...currentCompany.reportSettings,
          userId: getConsultantNamesById(currentCompany.reportSettings.userId),
          days: DAYS.filter((day) => {
            return currentCompany.reportSettings[day.toLowerCase()];
          }).map((day) => day[0].toUpperCase() + day.slice(1)),
        }}
        name="reports_form"
        layout="vertical"
        onFinish={sendReportConfig}
      >
        <Form.Item
          wrapperCol={{ xs: { span: 24 }, sm: { span: 16 }, md: { span: 16 }, lg: { span: 6 } }}
          label={"Time:"}
          name="time"
          validateTrigger={["onBlur"]}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <TimePicker placeholder="Select time" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ xs: { span: 24 }, sm: { span: 16 }, md: { span: 16 }, lg: { span: 6 } }}
          label={"Consultants:"}
          name="userId"
          validateTrigger={["onBlur"]}
        >
          <Multiselect
            className={"multiselect"}
            rendererCallback={selectAllConsultants}
            placeholder="Consultants"
            options={currentCompany.consultants}
            field={"name"}
            dropdownRender={true}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ xs: { span: 24 }, sm: { span: 16 }, md: { span: 16 }, lg: { span: 6 } }}
          label={"Report days:"}
          name="days"
          validateTrigger={["onBlur"]}
        >
          <Multiselect sorted={false} className={"multiselect"} placeholder="Report days" options={DAYS} />
        </Form.Item>
        <Form.Item valuePropName="checked" label={"Status:"} name="enabled" validateTrigger={["onBlur"]}>
          <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item>
          <Button type="success" htmlType="submit" className="btn">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

Reports.propTypes = {
  allCompanies: PropTypes.arrayOf(PropTypes.object),
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
};

export default Reports;
