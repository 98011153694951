import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { sortByName } from "../../utils/helpers";

const { Option } = Select;

export default function SelectComponent({ placeholder, name, data, handleChange, value, mainField, loading }) {
  return (
    <Select
      name={name}
      placeholder={placeholder}
      className="select"
      value={value || "Choose item"}
      onChange={(item) => handleChange(item, name)}
      loading={loading}
    >
      {data &&
        data.length &&
        sortByName(data, mainField).map((company, index) => {
          return (
            <Option key={index} value={company[mainField]}>
              {company[mainField]}
            </Option>
          );
        })}
    </Select>
  );
}

SelectComponent.defaultProps = {
  loading: false,
};

SelectComponent.propTypes = {
  value: PropTypes.string,
  mainField: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};
