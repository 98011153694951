import React, { useState, useEffect } from "react";
import { Modal, Select, Row, Col, Input, Button, Rate } from "antd";
import API from "../../../services/apiService";
import Form from "antd/lib/form";
import { useSelector } from "react-redux";
import { sortForSelectByAsc } from "../../../utils/helpers";
import PropTypes from "prop-types";
import "../_meetingPlanPage.less";

const { TextArea } = Input;

const LEVELS = [{ value: "new" }, { value: "repeated" }, { value: "ongoing" }, { value: "systemic" }];

export default function AddGuidanceModal({ title, isOpen, handleGuidanceModal, updateGuidance, currentRow }) {
  const [form] = Form.useForm();
  const systemUsers = useSelector((state) => state.meetingPlan.systemUsers);
  const selectedTeamMembers = useSelector((state) => state.meetingPlan.selectedTeamMembers);
  const [allGuidance, setGuidance] = useState([]);

  useEffect(() => {
    const setAllGuidance = async () => {
      const guidanceTopics = await API.get("lookup/guidanceTopic");
      setGuidance(guidanceTopics);
    };

    setAllGuidance();
  }, []);

  const onSubmit = async () => {
    await form.validateFields().then((data) => {
      let checkIfGuidanceExist = allGuidance.find((guidance) => guidance.text === data.guidanceArea);
      let guidanceArea =
        checkIfGuidanceExist && checkIfGuidanceExist.text ? checkIfGuidanceExist : { text: data.guidanceArea };
      const type =
        data.type && data.type !== "Consultant guidance" ? "Consultant guidance" : "Consultant guidance manual";
      updateGuidance({ ...data, guidanceArea, type });
      form.resetFields();
      handleGuidanceModal();
    });
  };

  const onCancel = () => {
    form.resetFields();
    handleGuidanceModal();
  };

  const transformCurrentItem = (currentRow) => {
    if (currentRow && Object.entries(currentRow).length) {
      const usersId = systemUsers.length
        ? systemUsers
            .filter((user) => {
              let containSelectedTeamMember = selectedTeamMembers.some((systemUser) => systemUser === user.id);
              let containInGuidance = currentRow.userName.some((systemUser) => systemUser === user.name);
              return containSelectedTeamMember && containInGuidance;
            })
            .map((user) => user.id)
        : [];

      return Object.entries(currentRow).reduce((acc, field) => {
        const fieldName = field[0] === "userName" ? "userId" : field[0];
        acc[fieldName] = field[0] === "userName" ? usersId : field[1];
        return acc;
      }, {});
    } else {
      return { userId: selectedTeamMembers };
    }
  };

  return (
    <Modal
      title={title}
      className="guidance_modal"
      centered
      maskClosable={false}
      visible={isOpen}
      onCancel={onCancel}
      onOk={onSubmit}
      footer={[
        <Button key="back" onClick={handleGuidanceModal}>
          Cancel
        </Button>,
        <Button key="success" type="primary" onClick={onSubmit}>
          OK
        </Button>,
      ]}
    >
      <Form form={form} name="guidance_form" layout="vertical" initialValues={transformCurrentItem(currentRow)}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={"Members:"} name="userId" rules={[{ required: true, message: "Field is required" }]}>
              <Select
                placeholder="Select team members"
                className="meetingPlan_form_item"
                mode="multiple"
                filterOption={(value, data) => {
                  if (data.children.includes(value)) return data.children;
                }}
                showArrow
              >
                {selectedTeamMembers.length
                  ? systemUsers
                      .filter((user) => selectedTeamMembers.findIndex((member) => member === user.id) !== -1)
                      .map((user) => {
                        return (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        );
                      })
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={"Guidance:"}
              name="guidanceArea"
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Select placeholder="Select topic" showSearch>
                {allGuidance &&
                  sortForSelectByAsc(allGuidance, "value").map((element, index) => {
                    return (
                      <Select.Option key={index} value={element["value"]}>
                        {element["value"]}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={"Level:"} name="level" rules={[{ required: true, message: "Field is required" }]}>
              <Select name="level" placeholder="Select level" options={LEVELS} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <div className="rate_wrapper">
            <span className="title">Select sentiment:</span>
            <Form.Item name="sentiment">
              <Rate />
            </Form.Item>
          </div>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="notes">
              <TextArea rows={4} placeholder="Notes" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AddGuidanceModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  handleGuidanceModal: PropTypes.func,
  updateGuidance: PropTypes.func,
  currentRow: PropTypes.object,
};
