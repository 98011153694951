import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import "./_timezoneDropdown.less";

const { Option } = Select;

export default function TimezoneDropdown({ timezones, onChange, value, className, onBlur }) {
  const renderOptions = () => {
    return (
      timezones.length &&
      timezones.map((timezone) => (
        <Option value={timezone.name} key={timezone.label}>
          {timezone.label}
        </Option>
      ))
    );
  };

  const transformTimezone = (timezoneValue) => {
    if (!timezoneValue) return null;
    return timezones.find((timezone) => timezone.name === String(timezoneValue))?.name;
  };

  return (
    <Select
      data-testid="timezoneDropdown"
      onBlur={onBlur ? onBlur : null}
      showSearch
      value={transformTimezone(value)}
      className={`select ${className}`}
      onChange={onChange}
      placeholder="Select timezone"
      filterOption={(input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {renderOptions()}
    </Select>
  );
}

TimezoneDropdown.defaultProps = {
  className: "",
};

TimezoneDropdown.propTypes = {
  timezones: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};
