import React, { useState } from "react";
import { Button, Space, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment";
import "./_rangePickerFilter.less";

const { RangePicker } = DatePicker;

export default function DateFilterComponent({ confirm, field, onSearch, filters, clearFilters }) {
  const [dateAfter, setStartDate] = useState(filters?.dateAfter || "");
  const [dateBefore, setEndDate] = useState(filters?.dateBefore || "");

  const handleSearch = () => {
    onSearch({ dateAfter, dateBefore });
    clearFilters();
    confirm();
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    onSearch({ dateAfter: "", dateBefore: "" });
    clearFilters();
    confirm();
  };

  const onChange = (value) => {
    let start = "";
    let end = "";
    if (value !== null) {
      start = moment(value[0]).format("YYYY-MM-DD");
      end = moment(value[1]).format("YYYY-MM-DD");
    }
    setStartDate(start);
    setEndDate(end);
  };

  const setRangeValue = () => {
    return dateAfter && dateBefore ? [moment(dateBefore, "YYYY-MM-DD"), moment(dateAfter, "YYYY-MM-DD")] : ["", ""];
  };

  return (
    <div className="date_filter_container">
      <RangePicker value={setRangeValue()} inputReadOnly={true} onChange={onChange} format={"YYYY-MM-DD"} />
      <Space className="date_filter_btn_container">
        <Button
          onClick={() => handleSearch(field)}
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          className="date_filter_btn"
        >
          Search
        </Button>
        <Button onClick={handleReset} type="dark" size="small" className="date_filter_btn reset">
          Reset
        </Button>
      </Space>
    </div>
  );
}

DateFilterComponent.propTypes = {
  field: PropTypes.string,
  onSearch: PropTypes.func,
  filters: PropTypes.shape({
    dateAfter: PropTypes.string,
    dateBefore: PropTypes.string,
  }),
  clearFilters: PropTypes.func,
  confirm: PropTypes.func,
};
