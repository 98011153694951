import React, { useCallback, useMemo, useState } from "react";
import ChipComponent from "../../../components/Chip/Chip";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { Button } from "antd";
import {
  addNewObjectionAction,
  deleteObjectionAction,
  editObjectionAction,
} from "../../../redux/actions/scriptPageActions";
import ScriptObjectionType from "./ScriptObjectionType";
import PropTypes from "prop-types";
import ScriptObjectionStageLoader from "./ScriptObjectionStageLoader";
import usePersistedState from "../../../hooks/usePersistedState";
import EditorToolbar from "./TextEditor/EditorToolbar";
import "../_script-page.less";

export default function ScriptObjectionSection({ currentStage, currentPage, allObjections, dispatch }) {
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedObjection, setSelectedObjection] = usePersistedState("selectedObjection", 0);

  const editStage = ({ name }) => {
    dispatch(editObjectionAction({ name, objectionIdx: selectedObjection, currentPage }));
  };

  const deleteStage = () => {
    setSelectedObjection(0);
    dispatch(deleteObjectionAction({ objectionIdx: selectedObjection, currentPage }));
  };

  const createNewStage = ({ name }) => {
    dispatch(addNewObjectionAction({ name, currentPage, objectionIdx: currentStage.objections.length }));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewStage(value);
    } else if (modalMode === "edit") {
      await editStage(value);
    } else if (modalMode === "delete") {
      await deleteStage();
    }
    setModalVisible(false);
  };

  const selectCurrentHandler = (value) => {
    setSelectedObjection(value);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const onChangeObjectionScript = (content, delta, source, editor) => {
    // Only fire an edit when the user makes a change, not on programatic change, otherwise we mess up our objection set.
    if (source === "user") {
      // console.log("Objection Changed", selectedObjection, content, source)
      dispatch(editObjectionAction({ script: content, objectionIdx: selectedObjection, currentPage }));
    }
  };

  const objectionsRender = useCallback(() => {
    return currentStage.objections.map((objection, index) => {
      return (
        <ChipComponent
          active={selectedObjection === index}
          key={index}
          editHandler={() => onModalOpen("edit")}
          deleteHandler={() => onModalOpen("delete")}
          selectCurrentHandler={() => selectCurrentHandler(index)}
          text={objection.name}
        />
      );
    });
  }, [currentStage.objections, selectedObjection]);

  const modals = [
    {
      title: "Delete",
      text: `Delete «${
        currentStage && currentStage.objections[selectedObjection] && currentStage.objections[selectedObjection].name
      }» objection`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit objection", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new objection", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type objection name",
          elementType: "input",
          checkDuplicate: true,
        },
      ];
    },
    []
  );

  return (
    <div className="section">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"name"}
          allItems={currentStage && currentStage.objections}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={currentStage.objections[selectedObjection]}
        />
      ) : null}
      <div className="config_panel">
        <h3 className="config_title">Objections:</h3>
        <Button className="button" type="primary" onClick={() => onModalOpen("add")}>
          Add objection
        </Button>
        <ScriptObjectionStageLoader
          allObjections={allObjections}
          selectedObjectionIdx={1}
          value={""}
          currentPage={currentPage}
        />
      </div>
      <div className="content_panel">{objectionsRender()}</div>
      {currentStage && currentStage.objections && currentStage.objections.length ? (
        <>
          <ScriptObjectionType
            selectedObjectionIdx={selectedObjection}
            currentPage={Number(currentPage)}
            type={currentStage.objections[selectedObjection] && currentStage.objections[selectedObjection].type}
          />
          <EditorToolbar
            value={currentStage.objections[selectedObjection] && currentStage.objections[selectedObjection].script}
            onChange={onChangeObjectionScript}
          />
        </>
      ) : (
        <div>There are no objections yet...</div>
      )}
    </div>
  );
}

ScriptObjectionSection.propTypes = {
  currentStage: PropTypes.object,
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
  allObjections: PropTypes.array,
};
