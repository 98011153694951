import React, { useEffect, useState } from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

export default function FilterDropdownLead({ dataIndex, confirm, onSearch, defaultValue, placeholder }) {
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSearch = (confirmHandler, searchedColumn) => {
    confirmHandler();
    onSearch({ searchText: value, searchedColumn });
  };

  const onChange = (event) => setValue(event.target.value);

  return (
    <div style={{ padding: "20px 10px 15px 10px" }}>
      <Input
        placeholder={`Search ${placeholder ? placeholder : dataIndex}`}
        onChange={(event) => onChange(event)}
        onPressEnter={() => handleSearch(confirm, dataIndex)}
        value={value}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => handleSearch(confirm, dataIndex)}
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 120 }}
        >
          Search
        </Button>
      </Space>
    </div>
  );
}

FilterDropdownLead.propTypes = {
  dataIndex: PropTypes.string,
  confirm: PropTypes.func,
  onSearch: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};
