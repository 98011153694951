export const validationAntService = () => {
  const validatePassword = async (rule, value) => {
    if (value) {
      const passwordRegexp = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/;
      if (!passwordRegexp.test(String(value))) {
        return Promise.reject(
          "Invalid password format.Password must contain numbers and letters and be at least 8 characters"
        );
      }
      await Promise.resolve(value);
    }
  };

  const validatePasswordDuplicate = async (rule, value, password) => {
    if (!value) {
      return await Promise.resolve(value);
    }
    if (value !== password) {
      return Promise.reject("Password and confirmation password do not match");
    }
    await Promise.resolve(value);
  };

  const validateEmail = async (rule, value) => {
    if (value) {
      const emailRegexp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (!emailRegexp.test(String(value))) {
        return Promise.reject("Invalid email format");
      }
      await Promise.resolve(value);
    }
  };

  const validateMaxLength = async (rule, value, maxLength) => {
    if (value) {
      const MAX_LENGTH = maxLength || 80;
      if (value && value.length > MAX_LENGTH) {
        return Promise.reject(`Name too long. Max length is ${MAX_LENGTH} symbols`);
      }
      await Promise.resolve(value);
    }
  };

  const validatePhone = async (rule, value) => {
    if (value) {
      const phoneRegexp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{4})\2([0-9]{4})/;
      if (!phoneRegexp.test(String(value))) {
        return Promise.reject("Invalid phone format");
      }
      await Promise.resolve(value);
    }
  };

  const validateFieldDuplicate = async (rule, value, allItems, mainField, mode, currentItem) => {
    if (value) {
      if (mode === "add" || mode === "clone") {
        const check = allItems.some((el) => el[mainField || rule["field"]] === value);
        if (check) {
          return Promise.reject(`Field duplicate! There is ${mainField || rule["field"]} with the same value`);
        }
        await Promise.resolve(value);
      }

      if (mode === "edit") {
        const editAdditionalData = allItems.filter((el) => el.id !== currentItem.id);
        const check = editAdditionalData.some((el) => el[mainField] === value);
        if (check) {
          return Promise.reject(`Field duplicate! There is ${mainField} with the same value`);
        }
        await Promise.resolve(value);
      }

      if (mode === "move") {
        const editAdditionalData = allItems.filter((el) => el.id !== currentItem.id);
        const selectedCompany = editAdditionalData.find((el) => el[mainField] === value);
        if (!selectedCompany) {
          return;
        }
        const check = selectedCompany.scripts.some((el) => el[mainField] === currentItem.name);

        if (check) {
          return Promise.reject(
            `Name duplicate!There is script with the same name in selected company.Please change script name`
          );
        }
        await Promise.resolve(value);
      }
    }
  };

  const validateAlphabet = async (rule, value) => {
    if (value && !Array.isArray(value)) {
      const regexp = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<>=?@[\]{}\\^_`~\s|]+$/gm;
      if (!regexp.test(String(value).toLowerCase())) {
        return Promise.reject("Available characters are latin alphabet, digits and special symbols");
      }
      await Promise.resolve(value);
    }
  };

  return {
    validatePassword,
    validatePasswordDuplicate,
    validateEmail,
    validateFieldDuplicate,
    validateMaxLength,
    validatePhone,
    validateAlphabet,
  };
};
