import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "../../components/DatePicker/DatePicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setMeetingDateAction,
  setTeamMembersAction,
  unsetTeamMemberAction,
} from "../../redux/actions/meetingsPlanActions";
import { Form, Popconfirm } from "antd";
import MeetingPlanMembersSelect from "./MeetingPlanMembersSelect";
import { useHistory } from "react-router-dom";
import { getUrlParam } from "../../utils/helpers";
import "./_meetingPlanPage.less";

const dateFormat = "YYYY-MM-DD HH:mm";

function MeetingPlanConfig() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const systemUsers = useSelector((state) => state.meetingPlan.systemUsers);
  const meetingDate = useSelector((state) => state.meetingPlan.date);
  const selectedTeamMembers = useSelector((state) => state.meetingPlan.selectedTeamMembers);
  const [isConfirm, setConfirm] = useState(false);
  const [deletedLead, setDeletedLead] = useState([]);

  useEffect(() => {
    form.resetFields();
  }, [selectedTeamMembers, meetingDate]);

  const getSearchUrl = ({ date, members }) => {
    const meetingId = getUrlParam(history.location.search, "id");
    const id = meetingId ? "&id=" + meetingId : "";
    const meetingDate = date ? "&date=" + date : "";
    const usersId = members && members.length ? members.map((memberId) => "&userId[]=" + memberId).join("") : "";
    let res = `?${id}${meetingDate}${usersId}`;
    return res[1] === "&" ? res.substring(0, 1) + res.substring(2, res.length) : res;
  };

  const setMeetingDate = (date, stringDate) => {
    dispatch(setMeetingDateAction(stringDate));
    history.push({
      pathname: "/meeting-plan",
      search: getSearchUrl({
        members: selectedTeamMembers,
        date: stringDate,
      }),
    });
  };

  const selectTeamMembers = async (values) => {
    form.setFieldsValue({ members: [...selectedTeamMembers, values] });
    history.push({
      pathname: "/meeting-plan",
      search: getSearchUrl({
        members: [...selectedTeamMembers, values],
        date: meetingDate,
      }),
    });
    return dispatch(setTeamMembersAction(values));
  };

  const unselectTeamMember = (value) => {
    setTimeout(() => {
      setConfirm(true);
    }, 100);
    const deletedLead = systemUsers.find((user) => user.id === value);
    setDeletedLead(deletedLead);
  };

  const confirmTeamMemberUnselect = (e) => {
    e.stopPropagation();
    form.setFieldsValue({ members: selectedTeamMembers.filter((user) => user !== deletedLead.id) });
    setConfirm(false);
    dispatch(unsetTeamMemberAction(deletedLead.id));
    history.push({
      pathname: "/meeting-plan",
      search: getSearchUrl({
        members: selectedTeamMembers.filter((user) => user !== deletedLead.id),
        date: meetingDate,
      }),
    });
  };

  const closeConfirm = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      setConfirm(false);
    }, 100);
  };

  const getInitialValues = useMemo(
    () => () => {
      return {
        date: meetingDate ? moment(meetingDate, dateFormat) : "",
        members: selectedTeamMembers,
      };
    },
    [meetingDate, selectedTeamMembers]
  );

  return (
    <div className="table_config">
      {isConfirm ? (
        <Popconfirm
          visible={isConfirm}
          placement="top"
          title={`Are you sure you want to delete ${deletedLead.name} ? All unsaved guides will be deleted.`}
          onConfirm={(e) => confirmTeamMemberUnselect(e)}
          onCancel={(e) => closeConfirm(e)}
          okText="Yes"
          cancelText="No"
        >
          <div className="popup_block"></div>
        </Popconfirm>
      ) : null}
      <Form
        form={form}
        name="meetingConfig_form"
        layout="vertical"
        className="login-form"
        initialValues={getInitialValues()}
      >
        <Form.Item
          label={"Date:"}
          name={"date"}
          validateTrigger={["onChange"]}
          rules={[{ required: true, message: `Date is required` }]}
        >
          <DatePicker
            className="meetingPlan_form_item"
            onChange={setMeetingDate}
            showTime={{ defaultValue: moment("00:00", "HH:mm") }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item
          label={<span>Members:</span>}
          name={"members"}
          validateTrigger={["onChange"]}
          rules={[{ required: true, message: `Choose at leas 1 team member` }]}
        >
          <MeetingPlanMembersSelect
            className="meetingPlan_form_item"
            selectTeamMembers={selectTeamMembers}
            systemUsers={systemUsers}
            unselectTeamMember={unselectTeamMember}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default MeetingPlanConfig;
