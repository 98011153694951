import React from "react";
import { Col, Row, Select } from "antd";
import TimezoneDropdown from "../../../components/TimezoneDropdown/TimezoneDropdown";
import PropTypes from "prop-types";

const { Option } = Select;

const HOURS = new Array(24).fill(null).map((_, i) => (i + 1 < 10 ? `0${i + 1}` : `${i + 1}`));

function StatsScheduleConfigTimeSection({
  currentHour,
  timezones,
  pickHoursHandler,
  pickTimezoneHandler,
  currentTimezone,
  isDirty,
  setDirty,
}) {
  const setFieldsDirty = (field, value) => setDirty(() => ({ ...isDirty, [field]: value === null }));

  return (
    <>
      <Row className="config_row">
        <Col xs={24} sm={24} md={12} lg={12} xl={5}>
          <span className="required-mark">
            *<p>&#160;Time of collection:</p>
          </span>
          <Select
            className={`select ${isDirty.hour && !currentHour && "error-field"}`}
            onChange={pickHoursHandler}
            value={currentHour}
            onBlur={() => setFieldsDirty("hour", currentHour)}
            placeholder="Select time of collection"
          >
            {HOURS.map((hour) => (
              <Option key={hour}>{hour}</Option>
            ))}
          </Select>
          {isDirty.hour && !currentHour && <span className="error-mark">This field is required</span>}
        </Col>
      </Row>
      <Row className="config_row">
        <Col xs={24} sm={24} md={12} lg={12} xl={5}>
          <span className="required-mark">
            *<p>&#160;Timezone:</p>
          </span>
          <TimezoneDropdown
            timezones={timezones}
            className={`${isDirty.timezone && !currentTimezone && "error-field"}`}
            value={currentTimezone}
            onChange={pickTimezoneHandler}
            onBlur={() => setFieldsDirty("timezone", currentTimezone)}
          />
          {isDirty.timezone && !currentTimezone && <span className="error-mark">This field is required</span>}
        </Col>
      </Row>
    </>
  );
}

StatsScheduleConfigTimeSection.propTypes = {
  currentHour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pickHoursHandler: PropTypes.func,
  pickTimezoneHandler: PropTypes.func,
  currentTimezone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  isDirty: PropTypes.shape({
    hour: PropTypes.bool,
    timezone: PropTypes.bool,
  }),
  setDirty: PropTypes.func,
};

export default StatsScheduleConfigTimeSection;
