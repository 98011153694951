import React from "react";
import { Select } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";
import "./_autocomplete.less";

export default function MiAutocomplete({ value, data, onSelect, field, placeholder, testId }) {
  return (
    <Select
      data-testid={testId}
      placeholder={placeholder}
      showSearch
      className={"autocomplete"}
      onSelect={onSelect}
      value={value}
    >
      {data &&
        sortByName(data, field).map((item, index) => {
          return (
            <Select.Option key={index} value={item[field]}>
              {item[field]}
            </Select.Option>
          );
        })}
    </Select>
  );
}

MiAutocomplete.propTypes = {
  value: PropTypes.string || null,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  field: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
};
