import React, { useState } from "react";
import { Button, Space, Tooltip } from "antd";
import Checkbox from "antd/lib/checkbox";
import PropTypes from "prop-types";
import { sortByName } from "../../utils/helpers";
import "./_checkboxFilter.less";

/*
   confirm - native ant design function close popup
   data - all data
   defaultValue - initial value
   dataIndex - name of field for search
   search - callback that implement filters
 */
export default function CheckboxFilter({ data, defaultValue, dataIndex, search, confirm, clearFilters }) {
  const [localValue, setLocalValue] = useState(defaultValue);

  const handleSearch = () => {
    confirm();
    search({ searchText: localValue, searchedColumn: dataIndex });
  };

  const handleReset = () => {
    clearFilters();
    setLocalValue([]);
    search({ searchText: [], searchedColumn: dataIndex });
  };

  const onChange = (id) => {
    if (localValue.find((localId) => localId === id)) {
      return setLocalValue((localValue) => {
        return localValue.filter((localId) => localId !== id);
      });
    }
    setLocalValue([...localValue, id]);
  };

  return (
    <div className="checkboxFilter_wrapper">
      <div className="data_section">
        {data && data.length
          ? sortByName(data, "name").map((checkboxItem) => {
              return (
                <Tooltip
                  title={`${checkboxItem.name} ${checkboxItem.email ? "(" + checkboxItem.email + ")" : ""}`}
                  key={checkboxItem.id}
                >
                  <Checkbox
                    key={checkboxItem.id}
                    checked={localValue.find((id) => id === checkboxItem.id)}
                    onChange={({ target: { checked } }) => onChange(checkboxItem.id, checked)}
                  >
                    {checkboxItem.name}
                  </Checkbox>
                </Tooltip>
              );
            })
          : ""}
      </div>
      <Space className="button_wrapper">
        <Button className="ok_btn" onClick={() => handleSearch()} type="primary" size="small">
          OK
        </Button>
        <Button
          disabled={!localValue.length}
          className="reset_btn"
          onClick={() => handleReset()}
          type="primary"
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
}

CheckboxFilter.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.arrayOf(PropTypes.string)]),
  data: PropTypes.arrayOf(PropTypes.object),
  dataIndex: PropTypes.string,
  search: PropTypes.func,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
};
