import React, { useState, useEffect } from "react";
import UserButtonGroup from "../../components/UserButtonGroup/UserButtonGroup";
import { Button, Modal, Spin, Input } from "antd";
import PropTypes from "prop-types";
import "./_script-viewer-page.less";

const { TextArea } = Input;

export default function QuestionModal({ title, isVisible, finish, closeModal, items, selected, selectedQuestion }) {
  const [selectedItem, setSelectedItem] = useState({});
  const [questionMode, setQuestionMode] = useState("");
  const [textAnswerValue, setTextAnswerValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setQuestionMode(selectedQuestion.questionType);
    if (selectedQuestion.questionType === "text" && selected !== null) {
      setTextAnswerValue(selected.name);
    }
    setLoading(false);
  }, []);

  const submitHandler = () => {
    const answer = questionMode === "text" ? { name: textAnswerValue } : selectedItem;

    finish(answer);
    setSelectedItem({});
    setQuestionMode("");
  };

  const closeModalHandler = () => {
    setSelectedItem({});
    setQuestionMode("");
    closeModal();
  };

  const selectEndReasonHandler = (value) => setSelectedItem(value);

  const buttonsAnswer = items.length
    ? items.map((entity) => {
        return {
          name: entity.name,
          action: () => selectEndReasonHandler(entity),
          visible: true,
          theme: "blue",
          themeSelected: "blue-empty",
          themeActive: "orange",
          isActive: selected !== null ? entity.name === selected.name : false,
          isActiveSelected: entity.name === selectedItem.name,
        };
      })
    : [];

  const checkItemChosen = () => !Object.entries(selectedItem).length && !textAnswerValue.length;

  const onTextAnswerChange = ({ target: { value } }) => setTextAnswerValue(value);

  const resetAnswerHandler = () => finish(null);

  const footer = [
    <Button key="1" onClick={closeModalHandler} type="orange-empty">
      Back to script
    </Button>,
    <Button
      key="3"
      className={`clear_answer ${selected && selected.name ? " visible" : ""}`}
      onClick={resetAnswerHandler}
      type="dark-empty"
    >
      Clear answer
    </Button>,
    <Button key="2" disabled={checkItemChosen()} onClick={submitHandler} type="orange">
      Submit
    </Button>,
  ];

  if (isLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      onCancel={closeModalHandler}
      title={title}
      centered
      className="choose_item_modal"
      visible={isVisible}
      footer={footer}
    >
      <div className="reason__viewer">
        <div className="reason__section">
          {questionMode === "text" ? (
            <div className="optionalAnswer">
              <TextArea
                value={textAnswerValue}
                onChange={onTextAnswerChange}
                className="textarea"
                placeholder="Type your answer"
                rows={9}
              />
            </div>
          ) : (
            <UserButtonGroup position="vertical" data={buttonsAnswer} />
          )}
        </div>
      </div>
    </Modal>
  );
}

QuestionModal.defaultProps = {
  selected: {},
};

QuestionModal.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  finish: PropTypes.func,
  closeModal: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
